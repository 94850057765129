<template>
  <div class="sp-store-cookies-policyy">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
  import TermsApi from '@/entities/terms/api/terms.api';
  import normalizeAnchors from '@/entities/terms/helpers/normalize-anchors.helper';

  export default {
    name: 'StoreCookiesPolicyContainer',
    props: {},

    data() {
      return {
        htmlContent: '',
      };
    },

    computed: {},

    beforeMount() {
      this.loadCookiesTerms();
    },
    methods: {
      async loadCookiesTerms() {
        try {
          this.htmlContent = await TermsApi.getCookies();

          this.$nextTick(() => {
            normalizeAnchors('sp-store-cookies-policyy');
          });
        } catch (err) {
          console.log('err', err);
        }
      },
    },

  };
</script>

<style lang="scss">
</style>
