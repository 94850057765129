<template>
  <store-cookies-policy-container/>
</template>

<script>
  import StoreCookiesPolicyContainer from '@/entities/terms/features/store-cookies-policy/store-cookies-policy.container.vue';

  export default {
    name: 'StoreCookiesPolicyView',
    components: { StoreCookiesPolicyContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
